import React from 'react';

const App = () => {
  const [bedtime, setBedtime] = React.useState('22:00');
  const [wakeTime, setWakeTime] = React.useState('06:00');
  const [sleepDuration, setSleepDuration] = React.useState(8);
  const [sleepCycles, setSleepCycles] = React.useState([]);
  const [sleepTip, setSleepTip] = React.useState('');

  const sleepTips = [
    "Create a consistent sleep schedule, even on weekends.",
    "Avoid caffeine and heavy meals close to bedtime.",
    "Make your bedroom dark, quiet, and cool for optimal sleep.",
    "Practice relaxation techniques like deep breathing before bed.",
    "Limit exposure to blue light from screens in the evening.",
    "Exercise regularly, but not too close to bedtime.",
    "Consider using white noise or nature sounds to help you fall asleep.",
    "Invest in a comfortable mattress and pillows for better sleep quality.",
    "Try to get some natural sunlight during the day to regulate your sleep cycle.",
    "Avoid long naps during the day, especially in the late afternoon.",
  ];

  React.useEffect(() => {
    setSleepTip(sleepTips[Math.floor(Math.random() * sleepTips.length)]);
  }, []);

  const calculateSleepCycles = () => {
    const bedDateTime = new Date(`2000-01-01T${bedtime}:00`);
    const cycles = [];
    for (let i = 1; i <= 6; i++) {
      const cycleTime = new Date(bedDateTime.getTime() + i * 90 * 60000);
      cycles.push(cycleTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    }
    setSleepCycles(cycles);
  };

  const handleBedtimeChange = (e) => {
    setBedtime(e.target.value);
    calculateSleepCycles();
  };

  const handleWakeTimeChange = (e) => {
    setWakeTime(e.target.value);
    const bedDateTime = new Date(`2000-01-01T${bedtime}:00`);
    const wakeDateTime = new Date(`2000-01-01T${e.target.value}:00`);
    let diff = (wakeDateTime - bedDateTime) / 3600000;
    if (diff < 0) diff += 24;
    setSleepDuration(Math.round(diff * 10) / 10);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-900 via-purple-900 to-pink-800 text-white font-sans p-8">
      <div className="max-w-md mx-auto bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl shadow-lg p-8">
        <h1 className="text-4xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
          🌙 Dreamy Sleep
        </h1>
        
        <div className="mb-6">
          <label className="block mb-2 text-lg text-purple-200">Bedtime 🛌</label>
          <input
            type="time"
            value={bedtime}
            onChange={handleBedtimeChange}
            className="w-full bg-purple-800 bg-opacity-50 border border-purple-500 rounded p-2 text-white"
          />
        </div>

        <div className="mb-6">
          <label className="block mb-2 text-lg text-purple-200">Wake time ⏰</label>
          <input
            type="time"
            value={wakeTime}
            onChange={handleWakeTimeChange}
            className="w-full bg-purple-800 bg-opacity-50 border border-purple-500 rounded p-2 text-white"
          />
        </div>

        <div className="mb-6">
          <p className="text-xl text-center text-purple-200">
            Sleep duration: <span className="font-bold text-pink-400">{sleepDuration} hours</span>
          </p>
        </div>

        {sleepCycles.length > 0 && (
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3 text-purple-200">Optimal wake-up times:</h2>
            <div className="grid grid-cols-3 gap-2">
              {sleepCycles.map((time, index) => (
                <div key={index} className="bg-purple-700 bg-opacity-50 rounded p-2 text-center">
                  <span className="text-lg font-medium">{time}</span>
                  <span className="block text-xs text-purple-300">({(index + 1) * 1.5}h)</span>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mt-8 p-4 bg-blue-900 bg-opacity-30 rounded-lg">
          <h3 className="text-lg font-semibold mb-2 text-blue-200">💡 Sleep Tip:</h3>
          <p className="italic text-blue-100">{sleepTip}</p>
        </div>
      </div>

      <div className="mt-8 text-center text-purple-300 text-sm">
        ✨ Optimize your sleep. Dream better. Live fuller. ✨
      </div>
    </div>
  );
};

export default App;
